import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OutboundLink } from "gatsby-plugin-google-gtag";
const ProductHighlightDrPepperCreamSodaZero = () => {
  return (
    <>
      <Row>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B08XXGB5NL/ref=as_li_tl?ie=UTF8&tag=exclusive008-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08XXGB5NL&linkId=d7df98203cffe698a37a02152f8df82a`}
          >
            <StaticImage src="../images/dr_pepper_cream_soda_zero_12_pack_cans.jpg" alt="dr pepper cream soda zero 12 pack cans" placeholder="blurred" width={300} height={300} />
            <br />
            12 fl oz cans, 12 Pack
          </OutboundLink>
        </Col>
      </Row>
    </>
  );
};
export default ProductHighlightDrPepperCreamSodaZero;
